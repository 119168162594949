import responseGenerator from '@/lib/util/dataSimulator/responseGenerator';
import {
  Collection,
  CollectionFormInput,
} from '@/lib/business';

import CollectionGroupEditSections
from '@/lib/ui/panels/CollectionGroup/CollectionGroupEditSections';
import CustomizationPanelManager from '@/lib/ui/panels/CustomizationPanelManager';
import FeaturedCollectionList from '@/lib/business/models/FeaturedCollectionList';
import CountListsResult from '@/lib/business/models/lists/CountListsResult';
import SearchParameters from '@/lib/search/discovery/classes/SearchParameters';
import { facetGrouping, FacetOrderType } from '@/lib/search/discovery/classes/facet';
import PaginatedResult from '@/lib/business/models/lists/PaginatedResult';
import InteractionInsight from '@/lib/insights/models/InteractionInsight';
import CollectionAssetSearchInput from '@/lib/business/models/CollectionAssetSearchInput';
import AssetDocumentForPreview from '@/lib/business/models/AssetDocumentForPreview';
import {
  DashboardMetrics,
  DiscoverySearchResult,
  DocumentInsight,
  InsightContent,
} from '../../lib/search/discovery';

const searchParams = new SearchParameters('', false);

export default {
  searchResults: {},
  currentPage: 1,
  rowsPerPage: 30,
  totalPages: 0,
  ciSearchResult: new DiscoverySearchResult(),
  discoverSearchResult: new DiscoverySearchResult(),
  facets: [],
  currentDocument: new InsightContent(),
  contentDocument: '',
  searchString: '',
  documentInsight: new DocumentInsight(),
  loading: false,
  responseGeneratorSections: responseGenerator.sections(),
  openResponseGeneratorPanel: false,
  dashboardMetrics: new DashboardMetrics(),
  responseGeneratorData: {
    name: 'Medical Research Center RFP',
    section: '',
    question1: '',
    question2: '',
  },
  questions: [],
  searchSuggestions: [],
  groups: [],

  selectedGroup: null,

  showGroupEditor: false,

  loadingCollections: false,

  collections: [],

  /* collection that's being edited or viewed currently */
  selectedCollection: new Collection(),

  /* holds data required to create a new collection or group */
  collectionFormInput: new CollectionFormInput(),

  /* This is used by edit collection or group panel to decide which entity should be updated.
  this could be collection or group */
  editableType: '',

  /* This will hold all the collections that are eligible to ve added to the selected group */
  availableCollections: [],

  /* this will hold all the groups that a collection can be associated with */
  availableGroups: [],

  /* holds value of insight to be shown in content insight modal */
  currentInsight: null,

  /* current phrase */
  currentInsightPhrase: null,

  collectionGroupEditSection: new CollectionGroupEditSections(),

  customizationPanelManager: new CustomizationPanelManager(),

  /**
   * any time a user attempts to update a document, this object will encapsulate the document
   * and the property the user is trying to update
   * @type {EditableAsset}
   */
  editableDocument: null,

  orderBy: null,

  orderByOptions: [
    { name: 'Published Date', value: 'published_date', type: 'date' },
    { name: 'Uploaded Date', value: 'created', type: 'date' },
    { name: 'Metadata Modified Date', value: 'metadata_storage_last_modified', type: 'date' },
  ],

  dashboardInsights: null,

  featuredCollections: new FeaturedCollectionList([]),

  featuredGroups: new FeaturedCollectionList([]),

  collectionCounts: new CountListsResult(),

  groupCounts: new CountListsResult(),

  selectedEditableEntity: null,

  searchError: null,

  searchParams,

  currentSearchRendererType: 'mobilecard',

  baseMetrics: new DashboardMetrics(),

  currentFilePreview: null,
  loadingFilePreview: false,

  /**
   * @type {EditPreviewInput}
   */
  editPreviewInput: null,

  facetGroups: facetGrouping,

  relatedCollectionsResult: new PaginatedResult(),

  selectedCollectionAssets: new PaginatedResult(),

  selectedPinnedCollectionAssets: new PaginatedResult(),

  documentInteraction: new InteractionInsight(),

  facetSortOrder: FacetOrderType.alphabeticAscending,

  dashboardInsightType: 'tagged',

  assetAssignments: null,

  availableUsersForAssignment: [],

  loadingMetrics: false,

  /**
   * @type{VideoIndexerWidgetSettings}
   */
  videoIndexerWidgetSettings: null,

  videoInsightsLoading: false,

  /**
   * Search Result for Assets page
   */
  collectionAssetSearchResult: new DiscoverySearchResult(),

  collectionAssetSearchParameters: new SearchParameters(''),

  collectionAssetSearchInput: new CollectionAssetSearchInput(),

  assetSetsResult: new PaginatedResult(),

  assetCampaignsResult: new PaginatedResult(),

  assetAwardsResult: new PaginatedResult(),

  assetExternalUrlResult: new PaginatedResult(),

  assetExternalUrlLabelResult: new PaginatedResult(),

  assetExternalUrlActionResult: new PaginatedResult(),

  assetExternalUrlDescriptionResult: new PaginatedResult(),

  assetRemovedFromCollection: false,

  showFacetChips: false,

  applyPublishedDateFilterOnPageLoad: false,

  appObjectInteractionInsights: new InteractionInsight(),

  awardsData: [],

  currentDocumentForPreview: new AssetDocumentForPreview(),
};
