class AssetDocumentForPreview {
  docId = '';

  url = '';

  constructor(docId = '', url = '') {
    this.docId = docId;
    this.url = url;
  }

  static fromObject({ docId, url }) {
    return new AssetDocumentForPreview(docId, url);
  }
}

export default AssetDocumentForPreview;
