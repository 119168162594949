import moment from 'moment';
import { mix } from 'mixwith/mixwith';
import Facet from '../facet';
import DateFilterWithQueryValueMixin
from './mixins/DateFilterWithQueryValueMixin';

export const byWordsDateFilterOptions = [
  {
    value: '-3 years',
    name: 'Past 3 years',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asYears() >= -3,
  },
  {
    value: '-2 years',
    name: 'Past 2 years',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asYears() >= -2,
  },
  {
    value: '-1 year',
    name: 'Past 12 months',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asMonths() >= -1,
  },
  {
    value: '-6 months',
    name: 'Past 6 months',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asMonths() >= -6,
  },
  {
    value: '-3 months',
    name: 'Past 3 months',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asMonths() >= -3,
  },
  {
    value: '-1 months',
    name: 'Past 1 month',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asMonths() >= -1,
  },
  {
    value: '-15 days',
    name: 'Past 15 Days',
    future: false,
    check: (diffInMilliseconds) => moment.duration(diffInMilliseconds).asDays() >= -15,
  },
];

const WordsDateFilter = class WordsDateFilter extends mix(Facet).with(
  DateFilterWithQueryValueMixin,
) {
  #filter = '';

  constructor(key) {
    super({ name: key, values: [] });
  }

  get filter() {
    return this.#filter;
  }

  set filter(value) {
    console.log('Setting Date workds value', value);
    if (value != null && value?.trim() !== '') {
      this.selectedValues = [value];
      this.selected = true;
      return;
    }

    this.#filter = value;
    this.selected = false;
    this.selectedValues = [];
  }
};

export default WordsDateFilter;
