export const shareableLocation = (rawLocation) => (preview) => {
  if (preview) {
    return { ...rawLocation };
  }

  return rawLocation;
};

export default {
  shareableLocation,
};
