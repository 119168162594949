export const EditSectionTypes = {
  DETAILS: 0, 
  THEME: 1,
  VISIBILITY: 2,
  MEMBERS: 3,
};

const CollectionGroupEditSections = function EditSections() {
  this.openSection = [EditSectionTypes.DETAILS, EditSectionTypes.THEME, EditSectionTypes.VISIBILITY, EditSectionTypes.MEMBERS];
};

CollectionGroupEditSections.prototype.setOpenPanel = function setOpenPanel(value) {
  this.openSection = value;
};

export default CollectionGroupEditSections;
