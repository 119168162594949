import moment from 'moment/moment';
import User, { userFactory } from '@/lib/business/models/User';
import CollectionUser from '@/lib/business/models/CollectionUser';
import CollectionAsset from '@/lib/business/models/CollectionAsset';
import collectionThemes from '@/lib/business/models/themes/collectionThumbnailProperties';
import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';
import setEditableFeaturesProperty from '@/lib/business/models/editable/editableType';
import UserWithRole from './UserWithRole';

const filterUserByRole = (collectionUsers) => (filter) => collectionUsers?.filter(filter)
  .map((user) => user.user);

class Collection {
  collectionId = 0;

  id = 0;

  name = '';

  description = '';

  /**
   *
   * @type {moment.Moment}
   */
  created = null;

  isFeatured = false;

  isFeaturedDate = null;

  isConfidential = true;

  createdByUser = null;

  /**
   *
   * @type {[CollectionAsset]}
   */
  collectionAssets = [];

  collectionUsers = [];

  heroImageData = null;

  heroImageUrl = '';

  color = '';

  owners = [];

  editors = [];

  viewers = [];

  collectionAssetsCount = 0;

  pinned = false;

  starred = false;

  isFeatured = false;

  isMember = false;

  theme = null;

  currentUser = null;

  isEditable = false;

  isSmart = false;

  propositionId = 0;

  deploymentIndexFilterId = 0;

  customizationSettings = null;

  viewsCount = 0;

  nameWithId = '';

  deploymentIndexId = 0;

  constructor(collection, user = null) {
    if (collection != null) {
      this.name = collection.name;

      this.collectionId = collection.collectionId;
      this.id = this.collectionId;

      this.description = collection.description;
      this.created = moment(collection.created);
      this.isFeatured = collection.isFeatured;
      this.isConfidential = collection.isConfidential;
      this.collectionAssetsCount = collection.collectionAssetsCount;

      this.createdByUser = collection.createdByUser && new User(collection.createdByUser);

      this.collectionAssets = CollectionAsset.createList(collection.collectionAssets);

      this.pinned = collection?.pinned;
      this.starred = collection?.starred;

      this.theme = discoveryThemeFactory(collection?.theme);

      if (typeof collection.isSmart !== 'undefined') {
        this.isSmart = collection.isSmart;
      } else {
        this.isSmart = false;
      }

      this.propositionId = collection.propositionId;

      this.deploymentIndexFilterId = collection.deploymentIndexFilterId;

      this.setMembers(collection.collectionUsers);
      if (user != null) {
        this.currentUser = user;
        this.updateIsMember(user.email);
      }

      this.customizationSettings = null;

      this.isFeaturedDate = collection.isFeaturedDate;
      this.viewsCount = null;
      this.nameWithId = collection.nameWithId;
      this.deploymentIndexId = collection.deploymentIndexId;
    }
  }

  get assetCount() {
    return this.collectionAssetsCount;
  }

  /**
   *
   * @returns {[CollectionUser]}
   */
  get members() {
    return this.collectionUsers;
  }

  set members(value) {
    this.collectionUsers = value;
  }

  get id() {
    return this.collectionId;
  }

  /**
   * This return raw location that could be resolved to a url
   */
  get rawLocation() {
    return { name: 'collection-details-assets', params: { id: this.id } };
  }

  shareableObject() {
    return {
      name: this.name,
      type: 'Collection',
      location: this.rawLocation,
    };
  }

  /**
   *
   * @param userEmail{string}
   * @returns {Collection}
   */
  updateIsMember(userEmail) {
    const allUsers = [...this.editors, ...this.owners];
    const isUserPresent = (userObj) => userObj.email?.toLowerCase() === userEmail.toLowerCase();

    this.isEditable = allUsers.some(isUserPresent);

    this.isMember = this.isEditable || this.viewers.some(isUserPresent);

    return this;
  }

  setMembers(collectionUsers) {
    if (collectionUsers == null) {
      return this;
    }
    /**
     * @type{UserWithRole[]}
     */
    this.collectionUsers = CollectionUser.createList(collectionUsers);

    const memberFactory = filterUserByRole(this.collectionUsers);
    /**
     *
     * @type {User[]}
     */
    this.editors = memberFactory(({ role }) => role.code === 'collection_editor');

    /**
     *
     * @type {User[]}
     */
    this.viewers = memberFactory(({ role }) => role.code === 'collection_viewer');

    /**
     * @type {User[]}
     */
    this.owners = memberFactory(({ role }) => role.code === 'collection_owner');

    this.collectionUsersCount = collectionUsers?.length || 0;
    return this;
  }

  setCustomizationSettings(settings) {
    this.customizationSettings = settings;
  }

  setViewsCount(count) {
    this.viewsCount = count;
  }
}

Collection.prototype.theme = collectionThemes.defaultThemeProperty();

Collection.prototype.update = function update(collectionDetails) {
  if (collectionDetails.collectionId === this.id) {
    const {
      name,
      description,
      collectionUsers,
      collectionAssets,
      isConfidential,
    } = collectionDetails;

    this.name = name;
    this.collectionAssetsCount = collectionAssets?.length;
    this.description = description;
    // this.isConfidential = isConfidential || true;
    this.isConfidential = isConfidential;
    this.setMembers(collectionUsers);
  }

  return this;
};

setEditableFeaturesProperty(Collection.prototype, {
  hasConfidentialProperty: {
    get() {
      return true;
    },
  },
  hasIsSmartValue: {
    get() {
      return true;
    },
  },
});

export default Collection;

export const collectionFactory = (data, user) => (data && new Collection(data, user)) || null;

export const collectionListFactory = (list) => list?.map(
  (collection) => new Collection(collection),
);
