import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('discovery', [
      'ciSearchResult',
      'dashboardInsights',
      'dashboardMetrics',
    ]),
    ...mapState('common', [
      'userDeployments',
    ]),
    currentDeployment() {
      return this.userDeployments?.currentDeployment;
    },
    /**
     *
     * @returns {DeploymentIndex|number|*}
     */
    currentDeploymentIndex() {
      return this.currentDeployment?.currentIndex;
    },
    currentDeploymentIndexId() {
      return this.currentDeployment?.currentIndex?.deploymentIndexId;
    },
    paletteFactory() {
      return this.currentDeploymentIndex.paletteFactory();
    },
    chartColor() {
      return this.currentDeployment?.primaryColor;
    },
    facets() {
      return this.dashboardMetrics?.facetList;
    },
    metrics() {
      return this.dashboardMetrics?.metrics;
    },
    assetEngagementInsight() {
      return this.dashboardMetrics.engagementInsights;
    },
    searchRequestInsight() {
      return this.dashboardMetrics.searchRequestInsights;
    },
  },
  methods: {
    ...mapActions('discovery', [
      'getDashboardInsights',
      'getMetrics',
    ]),
    ...mapMutations('discovery', [
      'setDashboardInsightType',
    ]),
  },
};
