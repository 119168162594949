import moment from 'moment';
import fileTypesUtil from '@/lib/util/fileTypesUtil';
import VideoIndexer from '@/lib/search/discovery/classes/insights/video/VideoIndexer';
import DescriptionList from '@/lib/search/discovery/classes/insights/DescriptionList';
import convertToSharepointDocument from '@/lib/search/discovery/classes/SharepointDocument';
import setContentAnalyserProperties
from '@/lib/search/discovery/analyzer/contentAnalyzerProperties';
import UploaderIndex from '@/lib/search/discovery/classes/UploaderIndex';
import User from '@/lib/business/models/User';
import { shareableLocation } from '@/lib/util/shareables';
import PageActions from '@/lib/ui/models/PageActions';

import InsightList from './insightList';
import { highlight } from './contentHighlighter';
// import documentTitle from '../../helpers';
import fragmentText from '../textExtractor';

const isAutoTag = (key) => (key || '').startsWith('indexer_');

const isManualTag = (key) => !((key || '').startsWith('indexer_') || (key || '')
  .startsWith('metadata_storage_'));

const autoTagFields = (o) => Object.keys(o).filter(isAutoTag);
const manualTagFields = (o) => Object.keys(o).filter(isManualTag);

const convertToUsers = (users, role) => users?.map((user) => User.fromIndexUser(user, role)) || [];

const instanceFields = () => ({
  content: true,
  metadata_content_type: true,
  metadata_storage_size: true,
  metadata_storage_last_modified: true,
  metadata_storage_file_extension: true,
  metadata_storage_path: true,
  metadata_storage_name: true,
  metadata_storage_content_type: true,
  ocrText: true,
  docText: true,
  doc_id: true,
  videoIndexerStoragePath: true,
  uploaderIndexerStoragePath: true,
  uploader: true,
  id: true,
  metadata_spo_item_name: true,
  metadata_spo_item_path: true,
  metadata_spo_item_content_type: true,
  metadata_spo_item_last_modified: true,
  metadata_spo_site_id: true,
  metadata_spo_library_id: true,
  metadata_spo_item_id: true,
  metadata_spo_site_library_item_id: true,
  metadata_spo_item_weburi: true,
  bindingId: true,
  document: true,
  confidential: true,
  description: true,
});

const extractFacetFields = (content) => {
  if (!content) {
    return [];
  }

  const contentKeys = Object.keys(content);
  const actualFields = instanceFields();

  return contentKeys.filter((key) => !actualFields[key]);
};

const getInstanceFields = () => Object.keys(instanceFields());

class InsightContent {
  content = '';

  contentType = '';

  storageSize = 0;

  lastModified = null;

  filename = null;

  storagePath = null;

  token = null;

  $facetFields = [];

  #insightObject = null;

  id = null;

  /**
   *
   * @type {InsightList|null}
   */
  insightList = null;

  hightlightedText = null;

  instanceFields = [];

  /**
   * This a structure that will hold the instance field and values as array
   * @type {*[]}
   */
  #documentData = null;

  /**
   * Specifies the current phrase that's used to highlight the transcript
   * @type {string}
   */
  #currentHighlightPhrase = '';

  /**
   * Highlighter instance with the
   * @type {ContentHighlighter}
   */
  contentHighlight;

  /**
   *
   * @type {TextFragments}
   */
  fragments = null;

  downloadURL = '';

  /**
   *
   * @type {Asset}
   */
  asset = null;

  /**
   *
   * @type {DescriptionList|null}
   */
  imageDescription = null;

  /**
   *
   * @type {UploaderIndex}
   */
  uploader = null;

  filePreviewUrl = null;

  confidential = null;

  publishedDate = null;

  contentTypeGroups = [];

  contentTypes = [];

  termsAndConditions = null;

  experts = [];

  owners = [];

  keyContacts = [];

  created = null;

  description = null;

  pageActions = new PageActions();

  lastUpdated = null;

  isAccessible = true;

  assetVisibility = 'Public';

  /**
   *
   * @param insightContentObj {Object}
   * @param decodedPath {String}
   * @param token {String}
   */
  constructor(insightContentObj, token, idField) {
    this.#insightObject = insightContentObj;
    this.document = insightContentObj;

    this.content = insightContentObj?.content;
    this.hightlightedText = this.content;
    this.contentType = insightContentObj && insightContentObj.metadata_content_type;
    this.storageSize = insightContentObj && insightContentObj.metadata_storage_size;
    this.lastModified = insightContentObj && insightContentObj.metadata_storage_last_modified;
    this.filename = insightContentObj && insightContentObj.file_name;
    this.storagePath = insightContentObj && (
      insightContentObj.storage_path || insightContentObj.metadata_storage_path
    );
    this.id = insightContentObj && insightContentObj[idField || 'doc_id'];
    this.description = insightContentObj && insightContentObj.description;

    this.token = token;
    this.confidential = insightContentObj && insightContentObj.confidential;
    this.publishedDate = insightContentObj && insightContentObj['published_date'];
    console.log('Published Date', this.publishedDate);
    this.contentTypeGroups = insightContentObj && insightContentObj['content_type_groups'];
    this.contentTypes = insightContentObj && insightContentObj['content_types'];
    this.termsAndConditions = insightContentObj && insightContentObj['terms_and_conditions'];
    this.owners = insightContentObj && convertToUsers(insightContentObj['author___contact'], User.RoleCodes.AUTHOR);
    this.keyContacts = insightContentObj && convertToUsers(insightContentObj['key_contacts'], User.RoleCodes.KEY_CONTACT);
    this.created = insightContentObj?.created && moment(insightContentObj?.created);
    this.experts = convertToUsers(
      insightContentObj && (insightContentObj['contributors___experts'] || []), User.RoleCodes.CONTRIBUTOR,
    );

    this.instanceFields = getInstanceFields();

    InsightList.create(insightContentObj, this.facetFields).then((insightList) => {
      this.insightList = insightList;
    });

    if (insightContentObj?.uploader) {
      this.uploader = new UploaderIndex(insightContentObj.uploader);
    }
    this.imageDescription = insightContentObj && new DescriptionList(insightContentObj.description);
    this.assetVisibility = (insightContentObj && insightContentObj['asset_visibility']) || 'Public';
    this.toSharepointDocument();
  }

  get hasIndexData() {
    return this.document && this.document.metadata_storage_path;
  }

  get hasBusinessData() {
    return this.document && this.document.storage_path;
  }

  get decodedPath() {
    if (this.hasBusinessData) {
      return this.storagePath;
    }

    return this.asset && this.asset.storagePath;
  }

  get path() {
    return this.decodedPath;
  }

  get title() {
    return this.document?.title;
  }

  get docId() {
    return this.document?.doc_id;
  }

  get fileType() {
    if (this.hasBusinessData) {
      return this.document && `.${this.document.file_extension}`;
    }

    if (this.hasIndexData) {
      return this.document && `.${this.document.metadata_storage_file_extension}`;
    }

    return this.asset?.fileExtension;
  }

  /**
   *
   * @returns {[<String>]}
   */
  get facetFields() {
    if (this.$facetFields.length === 0) {
      this.$facetFields = extractFacetFields(this.#insightObject);
    }

    return this.$facetFields;
  }

  highlight(phrase) {
    return highlight(this.content)(phrase);
  }

  /**
   *
   * @returns {VideoIndexer|null}
   */
  get videoIndexer() {
    return this.insightList?.videoIndexer;
  }

  get celebrities() {
    return this.#insightObject && this.#insightObject.celebrityNames;
  }

  get contentPath() {
    return `${this.decodedPath}${this.token}`;
  }

  get URL() {
    const { defaultPdfPreview } = this;

    if (defaultPdfPreview) {
      return `${defaultPdfPreview}`;
    }
    /* if (this.hasBusinessData) {
      return this.contentPath;
    } */

    return this.asset && `${this.asset.storagePath}`;
  }

  get previewUrl() {
    return this.asset && this.asset.previewUrl(this.token);
  }

  get defaultPdfPreview() {
    return this.asset?.defaultPdfPreview;
  }

  get documentData() {
    if (this.#documentData == null) {
      this.#documentData = this.instanceFields
        .map((field) => ({ field, value: this.#insightObject[field] }));
    }

    return this.#documentData;
  }

  get basicInsights() {
    return this.insightList && this.insightList.basicInsights;
  }

  get complexInsights() {
    return this.insightList && this.insightList.complexInsights;
  }

  get currentHighlightPhrase() {
    return this.#currentHighlightPhrase;
  }

  get isConfidential() {
    if (this.confidential == null || !this.confidential) {
      return false;
    }

    if (typeof this.confidential === 'boolean') {
      return this.confidential;
    }

    if (typeof this.confidential === 'number') {
      return this.confidential !== 0;
    }

    const dict = {
      yes: true,
      no: false,
    };

    return dict[this.confidential.toLowerCase()];
  }

  get chartOptions() {
    const chartOptions = this.insightList?.insightsChartOptions || [];
    console.log('Returning chart options', chartOptions);

    return chartOptions;
  }

  /**
   *
   * @param value {String}
   */
  set currentHighlightPhrase(value) {
    /**
     * Videos dont have transcript. So, we don't set anything.
     */
    if (fileTypesUtil.isVideo(this.fileType)) {
      this.contentHighlight = highlight('')('');
    }

    const phrase = ((value && value.trim()) || '') === '*' ? '' : value;
    if (phrase !== this.#currentHighlightPhrase || this.contentHighlight == null) {
      this.#currentHighlightPhrase = phrase;
      this.contentHighlight = highlight(this.content)(phrase);

      /* we don't want to create fragments if the phrase is empty */
      this.setFragments(phrase);
    }
  }

  setFragments(phrase) {
    this.fragments = fragmentText(this.content)(phrase);
  }

  get hasTranscript() {
    return this.content && this.content.trim() !== '';
  }

  get allCelebrities() {
    return [...new Set(
      [
        ...this.celebrities || [],
        ...this.videoIndexer?.summarizedInsights?.faceNames || [],
      ],
    )];
  }

  get isVideo() {
    return fileTypesUtil.isVideo(this.fileType);
  }

  get isImage() {
    return fileTypesUtil.isImage(this.fileType);
  }

  get isKeyNoteFile() {
    return fileTypesUtil.isKeyNote(this.fileType);
  }

  isOfFileType(fileType) {
    return fileTypesUtil.is(this.fileType) === fileType;
  }

  /**
   * This return raw location that could be resolved to a url
   */
  get rawLocation() {
    return { name: 'content-page', params: { id: this.id } };
  }

  get videoInsights() {
    return this.videoIndexer?.summarizedInsights;
  }

  get autoTagFields() {
    return autoTagFields(this.document);
  }

  get manualTagFields() {
    if (!this.document) {
      return [];
    }

    return manualTagFields(this.document);
  }

  shareableObject(preview = false) {
    return {
      name: this.filename,
      type: 'Document',
      location: shareableLocation(this.rawLocation)(preview),
    };
  }

  /**
   *
   * @param asset {Asset}
   */
  setAssetDetails(asset) {
    this.asset = asset;
  }

  toSharepointDocument() {
    if (this.document?.isSharepointDocument) {
      convertToSharepointDocument(this);
    }
  }

  isPinnedToCollection(collectionId) {
    if (this.asset == null) {
      return false;
    }

    return this.asset.isPinnedToCollection(collectionId);
  }

  getMetadataByFields(fieldNames) {
    const metadata = fieldNames.reduce((acc, field) => {
      acc[field] = Object.prototype.hasOwnProperty.call(this.document, field) ? this.document[field] : [];
      // acc[field] = this.document?.hasOwnProperty(field) ? this.document[field] : [];
      return acc;
    }, {});
    return metadata;
  }
}

setContentAnalyserProperties(InsightContent.prototype);

export default InsightContent;
