import moment from 'moment-timezone';

class MaintenancePeriod {
  /**
   * @param {string} start - The start time in ISO 8601 format
   * @param {number} duration - The duration of the maintenance period
   * @param {string} type - The unit of the duration (minutes, hours, days)
   */
  constructor(start = moment().format('YYYY-MM-DDT00:00:00-04:00'), duration = 1, type = 'hours') {
    this.start = moment.tz(start, moment.tz.guess());
    this.end = this.start.clone().add(duration, type); // Clone to avoid modifying start
  }

  /**
   * Get the formatted maintenance period message.
   * @returns {string} - Formatted start and end times.
   */
  get message() {
    const startString = this.start.format('dddd MMM D, YYYY h:mm a');
    const endString = this.end.format('dddd MMM D, YYYY h:mm a');
    return `${startString} to ${endString}`;
  }

  /**
   * Create a MaintenancePeriod instance from a configuration string.
   * @param {string} config - The configuration string in the format "start,duration<type>".
   * @returns {MaintenancePeriod} - The created MaintenancePeriod instance.
   */
  static fromConfig(config) {
    const pattern = /^(?<start>.*?),(?<duration>\d+)(?<type>(minutes?|hours?|days?))$/;
    const matches = config.match(pattern);

    if (!matches) {
      return new MaintenancePeriod(); // Default to current time and 2 hours
    }

    const { start, duration, type } = matches.groups;
    return new MaintenancePeriod(start, parseInt(duration, 10), type);
  }
}

export default MaintenancePeriod;
