<template>
  <div>
    <v-overlay
          :z-index="zIndex"
          :value="overlay"
        ></v-overlay>
    <v-app-bar
      app
      class="globalbar"
      extension-height="auto"
      flat
      height="60px"
      clipped-left
      clipped-right
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon class="globalbarelement btn_standout mr-2 ml-0 " @click="globaldrawer = true" v-show="!barextension"
        v-bind="attrs" v-on="on">
          <v-icon class="material-symbols-outlined">menu</v-icon>
        </v-app-bar-nav-icon>
      </template>
      <span>Global menu</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
      <v-btn class="globalbarelement btn_standout mr-2" icon :to="{name: 'dashboard-home'}" v-show="!barextension && !hideFromHome"
      v-bind="attrs" v-on="on"
      >
        <v-icon size="28" class="material-symbols-outlined">other_houses</v-icon>
      </v-btn>
    </template>
    <span>Home</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="onEmbedPage" class="globalbarelement btn_standout mr-2" icon @click="$router.back()" v-show="!barextension"
        v-bind="attrs" v-on="on"
      >
        <v-icon size="28" class="material-symbols-outlined" aria-label="Go Back">arrow_circle_left</v-icon>
      </v-btn>
      </template>
      <span>Back</span>
      </v-tooltip>
      <h4 class="text-uppercase globalbarelement notranslate" v-if="showMobileName">{{ mobileName }}</h4>

      <div v-if="isDesktopSearch" class="globalbarelement"
      >Can't find what you're looking for?
      <v-dialog v-model="newContentForm" width="900" persistent scrollable
        :fullscreen="$vuetify.breakpoint.xsOnly">
          <template v-slot:activator="{ on, attrs }">
            <a
       v-bind="attrs"
                  v-on="on"
      class="font-weight-bold text-decoration-underline globalbarelement">Let us know.</a>
      </template>
      <v-card color="transparent">
        <v-card-title class="modalwindowheader rounded-t-lg">
          New Content Request
          <v-spacer/>
            <v-btn @click="newContentForm = false" icon class="close--text" ><v-icon class="material-symbols-outlined bold">Close</v-icon></v-btn>
            </v-card-title>
        <v-card-text class="white pt-6">
          <iframe frameborder="0" width="100%" height="600" src="
https://app.smartsheet.com/b/form/1fa77826a1ac44abba843f352e50adc4"></iframe>
        </v-card-text>
      </v-card></v-dialog>
      </div>

      <!--      <search-box-mini v-model="query"
      @search="search" v-if="showSearchMini"></search-box-mini> -->
      <v-spacer></v-spacer>
      <slot name="middle">
        <div no-gutters class="analyzer-toolbar-wrapper" v-if="$vuetify.breakpoint.smAndUp && onEmbedPage && !barextension && showContentMenu">
      <v-toolbar
        class="globalbar embed_toolbar"
        height="60px"
        style="position:relative;"
        flat
      >
        <v-spacer/>
        <div class="d-flex" v-if="$vuetify.breakpoint.smAndUp">
          <v-menu>
            <template #activator="{ on: onMenu }">
              <v-tooltip bottom>
                <template #activator="{ on: onTooltip }">
                  <v-btn :ripple="false" class="btn globalbarelement text-uppercase btndownloadmenu
                  btn_standout on_toolbar mx-2"
                  v-on="{ ...onMenu, ...onTooltip }" text><v-icon class="material-symbols-outlined mr-3">download</v-icon>
                        Download<v-icon class="material-symbols-outlined">arrow_drop_down</v-icon></v-btn>
                </template>

                <span>Download files for this content</span>
              </v-tooltip>
            </template>

            <v-list dense>
              <v-list-item @click="downloadDocument">
                <v-list-item-title>
                  Asset
                  <span v-if="storageSize">({{ storageSize | fileSize }})</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-title>Source ()</v-list-item-title>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-title>Metadata</v-list-item-title>
              </v-list-item>
              <v-list-item disabled>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                class="globalbarelement text-uppercase btn_standout on_toolbar mx-2"
                text
                @click="like"
                v-on="on"
                v-bind="attrs">
                <v-icon
                  class="material-symbols-outlined mr-3"
                  :class="{filled : isLiked}"
                  :color="isLiked ? 'liked_content' : 'globalbarelement'">
                  thumb_up
                </v-icon>
                Like
              </v-btn>
            </template>
            <span>Like this content</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                class="globalbarelement text-uppercase btn_standout on_toolbar mx-2"
                text
                @click="shareDocument"
                v-on="on"
                v-bind="attrs">
                <v-icon class="material-symbols-outlined mr-3 filled">share</v-icon>
                Share
              </v-btn>
            </template>
            <span>Share this content</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false" class="globalbarelement text-uppercase btn_standout on_toolbar mx-2"
                text
                @click="localAddToCollection"
                v-on="on"
                v-bind="attrs">
                <v-icon class="material-symbols-outlined mr-3">create_new_folder</v-icon>
                Add
              </v-btn>
            </template>
            <span>Add this content to a collection</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                class="globalbarelement text-uppercase btn_standout on_toolbar mx-2"
                active-class="menu-bar-selection-highlight font-weight-bold"
                text
                @click="fullscreenToggle"
                v-bind="attrs" v-on="on">
                <v-icon class="material-symbols-outlined mr-3">{{ isFullScreen ? 'fullscreen_exit' : 'fullscreen' }}</v-icon>
                Fullscreen
              </v-btn>
            </template>
            <span>Toggle fullscreen</span>
          </v-tooltip>
          <v-tooltip bottom v-if="showAnalyzeButton && false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :ripple="false"
                class="globalbarelement text-uppercase btn_standout on_toolbar mx-2"
                active-class="menu-bar-selection-highlight font-weight-bold"
                text
                @click="showChartsPanel"
                v-bind="attrs" v-on="on">
                <v-icon class="material-symbols-outlined mr-3">insert_chart</v-icon>
                Analyze
              </v-btn>
            </template>
            <span>View content analysis</span>
          </v-tooltip>
          <asset-ai-interactions-menu :is-global-bar="true" />
        </div>
        <v-spacer/>
      </v-toolbar>
    </div>
        <v-spacer></v-spacer>
      </slot>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
      <v-btn class="globalbarelement btn_standout mr-2 globalsearch" icon @click="toggleSearchPanel" v-show="!barextension && !hideFromHome"
      v-bind="attrs" v-on="on"
      >
        <v-icon size="28" class="material-symbols-outlined">search  </v-icon>
      </v-btn>
      </template>
      <span>Search</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
      <v-btn class="globalbarelement btn_standout mr-2" icon @click="askBot"
      v-bind="attrs" v-on="on"
             v-show="!barextension && showOpenAI && $vuetify.breakpoint.smAndUp"
      >
        <v-icon size="28">$aiChatStars</v-icon>
      </v-btn>
    </template>
    <span>AI Assistant</span>
      </v-tooltip>
      <!-- <search-box-with-suggester
        id="topbarsearchbox"
        v-model="query"
        @search="search"
        :activate-search-options.sync="showSearchOptions"
        v-if="showSearchMini"
        disable-suggester
        placeholder="Search">
        <template v-slot:search-options>
          <deployment-index-selector-menu
            :show-menu.sync="showSearchOptions"
            :indexes="deploymentindexes"
            @index-selected="indexSelected"
            :value="selectedIndex"
          >

          </deployment-index-selector-menu>
        </template>
      </search-box-with-suggester>
      <search-menu v-model="searchParameters"
                   @is-semantic-search-changed="onSemanticSearchChanged"/> -->

<!--      <v-btn @click="temptriggeraddcollection">Temp trigger AddCollection</v-btn>-->
<!--      <v-btn @click="temptriggereditmetadata">Temp trigger EditMetadata</v-btn>-->

      <v-btn v-show="barextension" text
             class="globalbar globalbarelement btn_standout" @click="toggleSearchPanel"
      >
        <span class="font-weight-bold">Close</span>
        <v-icon class="material-symbols-outlined filled" right size="20">disabled_by_default</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
      <v-btn class="globalbarelement d-sm-flex d-none btn_standout mr-2" icon @click="openNotification" v-if="!barextension"
      v-bind="attrs" v-on="on"
      >
        <v-badge :content="unreadNotifications" :value="unreadNotifications" color="notification"
                 overlap>
          <v-icon size="28" class="material-symbols-outlined">notifications</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <span>Notifications</span>
      </v-tooltip>

      <!-- uploader -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="globalbarelement btn_standout mr-2" icon @click="openUploader"
                 v-bind="attrs" v-on="on"
                 v-show="!barextension && showUploaderButton"
                 :disabled="!selectedIndex"
          >
            <v-icon size="28" class="material-symbols-outlined filled">upload</v-icon>
          </v-btn>
        </template>
        <span>Upload</span>
      </v-tooltip>

      <!-- uploader end -->
<!--
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
      <v-btn v-show="showUploaderButton" :disabled="!selectedIndex"
             class="globalbarelement d-sm-flex d-none btn_standout mr-2" icon @click="openUploader"
             v-bind="attrs" v-on="on"
      >
        <v-icon size="28" class="material-symbols-outlined filled">upload</v-icon>
      </v-btn>
    </template>
    <span>Upload</span>
    </v-tooltip>
-->

      <v-menu offset-y right eager  v-model="userPreferencesUI">
        <template v-slot:activator="{ on, attrs }">
           <v-badge :content="unreadNotifications"
           :value="unreadNotifications && $vuetify.breakpoint.xsOnly && !barextension"
           color="notification"

                 overlap>
                 <v-avatar v-bind="attrs" v-on="on" class="nav-profile-avatar" color="#d9d9d9" size="48" v-show="!barextension">
            <v-img v-if="hasPicture" :src="avatar"></v-img>
            <span class="notranslate" v-else> {{ avatar | initials }}</span>
          </v-avatar>
           </v-badge>
        </template>
        <v-card

          flat
          tile
          width="400"
        >
          <v-row align="center" justify="start" no-gutters class="pa-3">
            <v-col class="flex-grow-0 flex-shrink-1 mr-2">
              <v-avatar color="#d9d9d9" size="56">
                <v-img v-if="hasPicture" :src="avatar"></v-img>
                <span class="notranslate" v-else> {{ avatar | initials }}</span>
              </v-avatar>
            </v-col>
            <v-col class="notranslate username_useremail_wrapper">
              <div>{{ userName }}</div>
              <div>{{ userEmail}}</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list dense class="user_preferences_list">
            <v-list-item v-if="isAuthenticated && false">
              <v-switch
                v-model="$vuetify.theme.dark"
              ></v-switch>
              {{ $vuetify.theme.dark ? 'Dark' : 'Light' }} Theme
            </v-list-item>
            <v-list-item v-if="false">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Settings</v-list-item-content>
            </v-list-item>
            <v-list-item @click="openMySitesSelectorDialog">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined my-sites">enable</v-icon>
              </v-list-item-icon>
              <v-list-item-content>My sites</v-list-item-content>
            </v-list-item>

            <v-list-item @click="openNotification">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">Notifications</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Notifications</v-list-item-content>
            </v-list-item>

            <v-list-item @click="openPreferencesPanel">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">tune</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Preferences</v-list-item-content>
            </v-list-item>

            <v-list-item :href="userGuide"
            target="_blank" v-if="showUserGuide">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">menu_book</v-icon>
              </v-list-item-icon>
              <v-list-item-content>User Guide</v-list-item-content>
            </v-list-item>

            <v-list-item @click.stop="openBugForm">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">bug_report</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Report an issue</v-list-item-content>
            </v-list-item>
            <v-dialog v-model="showBugForm" width="648" persistent scrollable
        :fullscreen="$vuetify.breakpoint.xsOnly">

      <v-card color="transparent">
        <v-card-title class="modalwindowheader rounded-t-lg">
          Report An Issue
          <v-spacer/>
            <v-btn @click="showBugForm = false" icon class="close--text" ><v-icon class="material-symbols-outlined bold">Close</v-icon></v-btn>
            </v-card-title>
        <v-card-text class="white pt-6">
          <div class="d-flex justify-center pb-6"
          ><v-btn
            v-clipboard:copy="currentURL"
            v-clipboard:success="copyURL"
          >Copy Page URL</v-btn></div>
          <iframe frameborder="0" width="100%" height="400" src="
https://app.smartsheet.com/b/form/26b3d37029314c43a8a0c73316315cde"></iframe>
        </v-card-text>
      </v-card></v-dialog>
            <v-list-item>
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">translate</v-icon>
              </v-list-item-icon>
              <!-- <v-list-item-content>Translate</v-list-item-content> -->
              <v-list-item>
                <language-selector/>
              </v-list-item>
            </v-list-item>

            <v-list-item v-if="isAuthenticated" @click="logout">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Logout</v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="login">
              <v-list-item-icon class="pl-3 mr-6">
                <v-icon class="material-symbols-outlined">login</v-icon>
              </v-list-item-icon>
              <v-list-item-content>Login</v-list-item-content>
            </v-list-item>

          </v-list>
          <v-divider class="my-3" v-if="false"></v-divider>
        </v-card>
      </v-menu>
      <template  v-slot:extension>
        <v-expand-transition>
        <v-card v-if="barextension"
          class="searchexpandedpanel"
          flat
          tile
          width="100%"
        >
          <search-box
            v-if="showSearchMini"
            id="topbarsearchbox"
            v-model="searchParameters"
            placeholder="Search"
            :facet-list="facetList"
            @search="search"
            @semantic-search-option-changed="onSemanticSearchChanged">
            <template v-slot:search-options>
              <deployment-index-selector-menu
                :indexes="deploymentindexes"
                :show-menu.sync="showSearchOptions"
                :value="selectedIndex"
                @index-selected="indexSelected"
              >
              </deployment-index-selector-menu>
            </template>
          </search-box>
          </v-card>
        </v-expand-transition>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isAuthenticated"
      v-model="globaldrawer"
      :width="$vuetify.breakpoint.smAndUp ? 400 : 330"
      app
      class="global_nav"
      temporary>
      <!--<v-tabs v-model="portalAdminTabs" background-color="transparent"
              color="#000" grow>
        <v-tabs-slider color="brandcolour"></v-tabs-slider>
        <v-tab class="text-none">Portal</v-tab>
        <v-tab class="text-none" disabled></v-tab>
      </v-tabs>-->
      <!--<v-tabs-items v-model="portalAdminTabs" class="transparent">
        <v-tab-item>
          <template>-->
            <div class="mb-3">
              <v-btn block depressed tile @click="openMySitesSelectorDialog"><i class="material-symbols-outlined my-sites notranslate" size="28">enable</i>
              My sites
              </v-btn>
            </div>

            <navigation-list :items="pageGroups"/>
            <!--
                        <v-list class="portal-links mt-3 pl-0" dense nav>
                          <v-list-group
                            :value="true"
                            class="portal-links-subgroup"
                            no-action
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="black--text text-uppercase globalnavlist__header font-weight-bold">
                                  Main navigation
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>

                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'dashboard-home'}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-home-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="globalnavlist font-weight-light">
                                  Home
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'Search', query: {q: ''}}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-file-multiple-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="globalnavlist font-weight-light">
                                  Content
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'collections-home'}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-folder-multiple-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="globalnavlist font-weight-light">
                                  Collections
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'groups-home'}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-google-circles-communities</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="globalnavlist font-weight-light">
                                  Groups
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                        </v-list>
                        <v-divider/>
                        <v-list class="portal-links pl-0" dense nav>
                          <v-list-group
                            :value="false"
                            class="portal-links-subgroup"
                            no-action
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="black--text text-uppercase globalnavlist__header font-weight-bold">
                                  Dashboards
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'dashboard-insights'}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-chart-box-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="globalnavlist font-weight-light">
                                  Data Insights
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'dashboard-patterns'}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-chart-line</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="globalnavlist font-weight-light">
                                  Pattern Analysis
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                        </v-list>
                        <v-divider/>
                        <v-list class="portal-links pl-0" dense nav>
                          <v-list-group
                            :value="false"
                            class="portal-links-subgroup"
                            no-action
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="black--text text-uppercase globalnavlist__header font-weight-bold">
                                  In Development
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         :to="{name: 'response-generator-home'}" class="pl-2">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-layers-triple-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="globalnavlist font-weight-light">
                                  Response Generator
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}" class="pl-2"
                                         href="https://www.qnamaker.ai/Home/MyServices" target="_blank">
                              <v-list-item-icon>
                                <v-icon size="28">mdi-robot-outline</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="globalnavlist font-weight-light">
                                  Information Bots
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                        </v-list>
                        <v-divider/>
                        <v-list class="portal-links pl-0 admin" dense nav>
                          <v-list-group
                            :value="false"
                            class="portal-links-subgroup"
                            no-action
                          >
                            <template v-slot:activator>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="black--text text-uppercase globalnavlist__header font-weight-bold">
                                  Admin
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                            <v-list-item v-ripple="{ class: `brandcolour--text`}"
                                         href="https://ci-discoveryportal-admin.azurewebsites.net" target="_new" class="pl-2">
                              <v-list-item-icon>
                                <v-icon color="black" size="28">far fa-user-friends</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="globalnavlist font-weight-light">
                                  Admin Console
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-group>
                        </v-list>
            -->
            <v-list class="portal-links pl-0 instances" dense nav v-if="false">
              <v-list-group
                :value="false"
                class="portal-links-subgroup"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="black--text text-uppercase globalnavlist__header font-weight-bold">
                      Instances
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <deployment-selector class="py-4"
                                     @index-changed="indexChanged"></deployment-selector>
              </v-list-group>
            </v-list>

         <!--</template>
        </v-tab-item>-->
        <v-tab-item v-if="false">
          <template>
            <v-list class="portal-links pl-0 manage_nav" dense nav>
              <v-list-group
                :value="false"
                class="portal-links-subgroup"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="black--text text-uppercase globalnavlist__header font-weight-bold">
                      Manage
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'users-list'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">far fa-user-friends</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Users
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">far fa-database</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Data Sources
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fad fa-shield</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Rules
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fab fa-keycdn</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Groups
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fab fa-keycdn</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Collections
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fab fa-keycdn</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Response Templates
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">far fa-robot</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Knowledge Bases
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
            <v-divider/>
            <v-list class="portal-links pl-0 configure_nav" dense nav>
              <v-list-group
                :value="false"
                class="portal-links-subgroup"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="black--text text-uppercase globalnavlist__header font-weight-bold">
                      Configure
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">far fa-power-off</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Settings
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fab fa-keycdn</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Clients
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fab fa-keycdn</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Uploader
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-ripple="{ class: `brandcolour--text`}"
                             :to="{name: 'admin'}" class="pl-2 black--text">
                  <v-list-item-icon class="mt-4">
                    <v-icon color="black" size="16">fas fa-key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="globalnavlist font-weight-light">
                      Roles
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
            <v-divider/>
            <v-list class="portal-links pl-0 instances" dense nav>
              <v-list-group
                :value="false"
                class="portal-links-subgroup"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="black--text text-uppercase globalnavlist__header font-weight-bold">
                      Instances
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <deployment-selector class="py-4"
                                     @index-changed="indexChanged"></deployment-selector>
                <v-text-field v-model="deploymentIndexName" label="New Datasource Name"
                              type="text"></v-text-field>
                <v-btn @click="duplicateDeploymentIndex">Duplicate Index</v-btn>
                <br />
                <br />
                <br />
                <br />
                <v-btn @click="generateAssetMetadata">Convenience function, don't click</v-btn>
                <br />
                <br />
                <br />
                <br />
                <br />
                ParentFieldCode (lower case, no space, underscore allowed): <textarea v-model="parentFieldCode" style="background-color: #eeeeee"></textarea><br /><br />
                ParentFieldName (used if new): <textarea v-model="parentFieldName" style="background-color: #eeeeee"></textarea><br /><br />
                ParentFieldValue: <textarea v-model="parentFieldValue" style="background-color: #eeeeee"></textarea><br /><br />
                ParentOrder (used if new): <input type="number" v-model="parentFieldOrder" style="background-color: #eeeeee" /><br /><br />
                <br />
                <br />
                FieldCode (lower case, no space, underscore allowed): <textarea v-model="fieldCode" style="background-color: #eeeeee"></textarea><br /><br />
                FieldName (used if new): <textarea v-model="fieldName" style="background-color: #eeeeee"></textarea><br /><br />
                FieldValue: <textarea v-model="fieldValue" style="background-color: #eeeeee" rows="20" cols="30"></textarea><br /><br />
                FieldOrder (used if new): <input type="number" v-model="fieldOrder" style="background-color: #eeeeee" /><br /><br />
                <br /><br />
                <br />
                <v-btn @click="localCreateMetadataEdge">Create Metadata Edge</v-btn>
                <br />
                <br />
                <br />
              </v-list-group>
            </v-list>
          </template>
        </v-tab-item>
      <!--</v-tabs-items>-->

      <template v-slot:append>
        <div class="pa-5" style="font-size:.8rem;"
      >Release: {{ releaseId }}
      </div>
      </template>
    </v-navigation-drawer>

  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';

import common from '@/config/common';
import dashboardHomeMixin
from '@/components/dashboard/pages/home/templates/mixins/dashboardHomeMixin';
import DeploymentIndexSelectorMenu
from '@/components/common/deployments/DeploymentIndexSelectorMenu.vue';
import DeploymentSelector from '@/components/deployments/DeploymentSelector.vue';
// import SearchMenu from '@/components/search/SearchMenu.vue';
import SearchBox from '@/components/search/SearchBox.vue';
import NavigationList from '@/components/ui/navigation/NavigationList.vue';
import SearchLogInput, { SearchLocations } from '@/lib/search/discovery/models/SearchLogInput';
import { DiscoveryDialogTypes } from '@/lib/ui/dialogs/DiscoveryDialogsFactory';
import LanguageSelector from '@/views/experiment/Translate.vue';
import AssetAiInteractionsMenu from '@/components/content/asset/AssetAIInteractionsMenu.vue';
import analyzerPageMixins from '@/components/content/mixins/analyzerPageMixins';
import DiscoveryDialogOptions from './ui/dialogs/DiscoveryDialogOptions';
import ExternalUrlActionTypes from './content/analyzer/ExternalUrlActionTypes';

export default {
  components: {
    NavigationList,
    //  SearchMenu,
    DeploymentSelector,
    // SearchBoxMini,
    SearchBox,
    DeploymentIndexSelectorMenu,
    LanguageSelector,
    AssetAiInteractionsMenu,
  },
  mixins: [
    analyzerPageMixins,
    dashboardHomeMixin,
  ],
  props: {
    mobileName: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    userPreferencesUI: false,
    showBugForm: false,
    newContentForm: false,
    zIndex: 2,
    overlay: false,
    globaldrawer: false,
    searchQuery: '',
    testing: 'true',
    sharePanel: false,
    showSearchOptions: false,
    portalAdminTabs: 0,
    deploymentIndexName: '',
    barextension: false,
    isFullScreen: false,

    fieldCode: '',
    fieldName: '',
    fieldValue: '',
    fieldOrder: '',

    parentFieldCode: '',
    parentFieldName: '',
    parentFieldValue: '',
    parentFieldOrder: '',

  }),
  computed: {
    ...mapState('discovery', [
      'searchString',
      'searchParams',
      'assetExternalUrlActionResult',
    ]),
    ...mapGetters('discovery', [
      'facetList',
    ]),
    ...mapGetters('common', [
      'userName',
      'userEmail',
      'hasPicture',
      'avatar',
    ]),
    // temporary import until moved
    ...mapGetters('common', [
      'deployments',
      'deploymentindexes',
      'selectedDeployment',
      'selectedDeploymentIndex',
      'unreadNotifications',
    ]),
    ...mapState('common', [
      'userDeployments',
      'pageGroups',
      'indexPermissions',
    ]),
    onEmbedPage() {
      return this.$route.name === 'asset-embed';
    },
    currentURL() {
      return `${window.location.origin}${this.$route.fullPath}`;
    },
    hideFromHome() {
      return this.$route.meta?.hideHomeButton;
    },
    showNonAdminElements() {
      const { meta } = this.$route;

      if (!meta || !('isAdmin' in meta)) {
        return true;
      }

      const isAdminPage = this.$route.meta?.isAdmin;
      console.log('Show uploader: admin?', isAdminPage, !isAdminPage);
      return !isAdminPage;
    },
    query: {
      get() {
        return this.searchParams.q;
      },
      set(value) {
        this.searchQuery = value;
        this.searchParams.q = value;
      },
    },
    selectedDeploy: {
      get() {
        return this.selectedDeployment;
      },
      async set(value) {
        await this.setSelectedDeployment(value);
        this.search('');
      },
    },
    selectedIndex: {
      get() {
        return this.selectedDeploymentIndex;
      },
      async set(value) {
        this.setSelectedDeploymentIndex(value);
        this.search('');
      },
    },
    showSearchMini() {
      return !this.$route.meta.hideSearchFromHeader;
    },
    releaseId() {
      return common.releaseId();
    },
    isAuthenticated() {
      return this.userName !== null;
    },
    isSemanticSearch: {
      get() {
        return this.searchParams.isSemanticSearch;
      },
      set(value) {
        this.setIsSemanticQuery(value);
      },
    },
    searchParameters: {
      get() {
        return this.searchParams;
      },
      set(value) {
        this.setSearchParams(value);
      },
    },
    showMobileName() {
      if (this.isHomepage) {
        return true;
      }

      if (this.$vuetify.breakpoint.xsOnly && !this.barextension) {
        return true;
      }

      return false;
    },
    /* mobileName() {
      if (this.$route.meta.mobileName === 'sitename') {
        return this.selectedDeploymentIndex.customizationSettings?.getSetting('mobileTitle')?.value || '';
      }
      return this.$route.meta.mobileName;
    }, */
    showUploaderButton() {
      return this.showNonAdminElements && this.indexPermissions.canDo('uploadAsset');
    },
    showOpenAI() {
      return this.showNonAdminElements && (this.indexPermissions.canDo('viewOpenAi') || this.indexPermissions.canDo('viewAiAssistant'));
    },
    homeRoute() {
      const route = { name: 'dashboard-home' };
      return route;
    },
    isMobileHomepage() {
      return this.$vuetify.breakpoint.xsOnly && this.$route.meta.mobileName === 'sitename';
    },
    isDesktopSearch() {
      return this.$vuetify.breakpoint.smAndUp && this.$route.meta.mobileName === 'browse';
    },
    isHomepage() {
      return this.$route.meta.pageTitle === 'Home';
    },
    assetExternalUrlAction() {
      const externalUrlAction = this.assetExternalUrlActionResult?.rows?.[0] ?? null;
      return externalUrlAction;
    },
    showContentMenu() {
      return (this.assetExternalUrlAction.toLowerCase() === ExternalUrlActionTypes.POWER_BI_EMBED_WITH_STARFISH_TOOLBAR
      || this.assetExternalUrlAction.toLowerCase() === ExternalUrlActionTypes.POWER_BI_EMBED_WITH_ACTIONS_AND_STARFISH_TOOLBAR
      || this.assetExternalUrlAction.toLowerCase() === ExternalUrlActionTypes.TABLEAU_EMBED_WITH_STARFISH_TOOLBAR);
    },
    showUserGuide() {
      return this.userGuide && this.userGuide.trim().length > 0;
    },
  },
  methods: {
    ...mapMutations('common', [
      'setSelectedDeployment',
      'setSelectedDeploymentIndex',
      'updateSearchLog',
      'openMySitesSelectorDialog',
    ]),
    ...mapActions('common', [
      'setDeploymentIndex',
      'openNotification',
      'duplicateIndex',
      'generateAssetMetadataSets',
      'getPages',
      'setUserUserSelectedIndex',
      'openDialog',
      'openAskBot',
      // 'createMetadataEdge',
    ]),
    ...mapActions('uploader', [
      'openUploader',
      'createMetadataEdgeAction',
      'openEditMetadata',
      'openAddToCollection',
    ]),
    ...mapActions('discovery', [
      'openPreferencesPanel',
    ]),
    ...mapActions('assistant', [
      'addPromptInstructions',
    ]),
    ...mapMutations('discovery', [
      'setIsSemanticQuery',
      'setSearchParams',
      'resetContentFilters',
    ]),
    ...mapMutations('assistant', [
      'resetConversation',
      'setPenalTriggeredFrom',
    ]),
    ...mapActions('discovery', [
      'showChartsPanel',
    ]),
    fullscreenToggle() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        this.isFullScreen = true;
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
        this.isFullScreen = false;
      }
    },
    copyURL() {
      this.$root.SnackbarMessage.show({ message: 'URL copied to clipboard.', color: 'consolesuccess', textClass: 'consolesuccesstext--text' });
    },
    async search(query) {
      await this.updateSearchLog(new SearchLogInput(SearchLocations.GLOBAL, this.$route.name));
      this.barextension = false;
      this.overlay = false;
      this.$emit('start-search', query || this.searchQuery);
    },
    indexSelected(index) {
      this.selectedIndex = index;
    },
    async indexChanged(index) {
      this.resetContentFilters();
      await this.setDeploymentIndex(index);
      await this.setUserUserSelectedIndex(index);
      this.$emit('index-changed', index);
    },
    logout() {
      this.$emit('logout');
    },
    login() {
      this.$emit('login');
    },
    duplicateDeploymentIndex() {
      // console.log('in duplicate index');
      this.duplicateIndex({
        IndexId: this.selectedDeploymentIndex.deploymentIndexId,
        CopyName: this.deploymentIndexName,
      });
    },
    generateAssetMetadata() {
      // console.log('in duplicate index');
      this.generateAssetMetadataSets({
        IndexId: this.selectedDeploymentIndex.deploymentIndexId,
      });
    },
    localCreateMetadataEdge() {
      this.createMetadataEdgeAction({
        DeploymentIndexId: this.selectedDeploymentIndex.deploymentIndexId,
        FieldCode: this.fieldCode,
        FieldName: this.fieldName,
        FieldValue: this.fieldValue,
        FieldOrder: this.fieldOrder,
        ParentFieldCode: this.parentFieldCode,
        ParentFieldName: this.parentFieldName,
        ParentFieldValue: this.parentFieldValue,
        ParentFieldOrder: this.parentFieldOrder,
      });
    },
    onSemanticSearchChanged(value) {
      this.setIsSemanticQuery(value);
    },
    /* showSearchBar(value) {
      this.barextension = value;
      this.overlay = value;
    }, */
    toggleSearchPanel() {
      this.barextension = !(this.barextension);
      this.overlay = !(this.overlay);
    },

    temptriggereditmetadata() {
      this.openEditMetadata('16484114-890d-4213-8777-828190fe88e3');
    },
    temptriggeraddcollection() {
      this.openAddToCollection('16484114-890d-4213-8777-828190fe88e3');
    },
    askBot() {
      this.resetConversation();
      this.addPromptInstructions();
      this.setPenalTriggeredFrom('topnav');
      this.openAskBot();
    },
    openBugForm() {
      this.userPreferencesUI = false;
      this.showBugForm = true;
    },
  },
  watch: {
    $route(to, from) {
      document.exitFullscreen();
    },
  },
};
</script>

<style lang="scss">
.nav-profile {
  margin-left: 10px;
}

.datasources-wrapper {
  width: 100%;
  position: relative;

  fieldset {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  label.v-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.more-data-sources {
  width: 100%;
}

.position-wrapper {
  position: relative;
}

.position-example {
  position: absolute;
}

.theme--light {
  .subgroupheader, .hold-menu-text-colour {
    color: #707070;
    caret-color: #707070;
  }
}

.portal-links-subgroup {
  .v-list-group__header__prepend-icon {
    margin-right: 36px !important;
  }

  .v-list-group__header.v-list-item--link {
    &:before {
      background-color: #707070;
    }
  }
}

.instances .v-list-group__items .v-list-item {
  overflow: visible !important;
}
</style>
