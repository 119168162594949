const DateFilterWithQueryValueMixin = (superClass) => class extends superClass {
  get queryValues() {
    if (this.selected) {
      if (this.selected) {
        return {
          key: this.key,
          value: this.selectedValues,
        };
      }
    }

    return null;
  }
};

export default DateFilterWithQueryValueMixin;
