<script>
import common from '@/config/common';

export default {
  name: 'MaintenanceMessage',
  computed: {
    period() {
      return common.maintenancePeriod.message;
    },
  },
};
</script>

<template>
  <v-card color="#ebebeb" class="rounded-lg mt-n11 mt-sm-0" :max-width="$vuetify.breakpoint.smAndUp? 400 : '100%'" :flat="$vuetify.breakpoint.xsOnly">
    <v-card-text class="black--text pa-7">
      <v-row no-gutters align="center" justify="center" class="">
        <v-img src="/images/wpp-vector-logo.svg"
         max-width="133" max-height="40" class="mb-7"
        />
      </v-row>
      <div class="font-weight-bold text-uppercase text-h6">Scheduled Maintenance</div>
      <v-divider class="black my-4" />
<p>
  <strong>Notification:</strong> All Users, please be aware the site will be undergoing a brief scheduled maintenance from
  {{ period }}. During this time the site be unavailable.  We apologize in advance for any inconvenience that may be caused.
  </p>
  <p>Please check back shortly.</p>

<p class="font-weight-bold text-uppercase">WPP Enterprise Data Group</p>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
